<template>
  <footer id="footer" class="relative bg-blueGray-200 pb-6">
    <div
      class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
      style="transform: translateZ(0);"
    >
      <svg
        class="absolute bottom-0 overflow-hidden"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          class="text-blueGray-200 fill-current"
          points="2560 0 2560 100 0 100"
        ></polygon>
      </svg>
    </div>
    <div class="container mx-auto px-4">
      <div class="flex flex-wrap text-center items-center lg:text-left">
        <div class="w-full lg:w-6/12 px-4">
            <h4 class="text-3xl">
                <span class="font-semibold">您可以通过右侧方式联系我们!</span>
                <span class="text-lg mt-4 ml-4 mb-2 text-blueGray-600 text-right">我们将快速做出回复</span>
            </h4>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="flex flex-wrap justify-center items-center mb-6">
            <img :src="linlWxMa" class="w-24 align-middle rounded-lg" />
          </div>
          <div class="text-black text-center">QQ：1910668462、2358129948</div>
        </div>
      </div>
      <hr class="my-6 border-blueGray-300" />

      <div class="flex flex-wrap items-center md:justify-between justify-center">
        <div class="w-full md:w-4/12 px-4 mx-auto text-center">
          <div class="text-sm text-blueGray-500 font-semibold py-1">
            Copyright © {{ date }} 一根葱团队 by
            <a
              href="https://www.creative-tim.com?ref=vn-footer"
              class="text-blueGray-500 hover:text-blueGray-800"
            >
              Creative Tim
            </a>
            .
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import linlWxMa from '@/assets/img/link-wx-ma.jpg'
export default {
    data () {
        return {
            linlWxMa,
            date: new Date().getFullYear()
        }
    }
}
</script>
