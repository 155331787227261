<template>
    <div>
        <index-navbar />
        <!-- 首屏 -->
        <section class="header relative pt-16 items-center flex h-screen max-h-860-px">
            <div class="container mx-auto items-center flex flex-wrap">
                <div class="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
                    <div class="pt-32 sm:pt-0">
                        <h2 class="font-semibold text-4xl text-blueGray-600">
                            我们是
                        </h2>
                        <p class="mt-10 text-lg leading-relaxed text-blueGray-500 t-indent-38">
                            专注于网站、微信、小程序、App、大屏可视化开发的服务商, 为个人、企业提供一站式产品设计与技术开发的外包服务。
                        </p>
                        <div class="mt-12">
                            <a href="#footer" class="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-emerald-500 active:bg-emerald-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150">
                                联系我们
                            </a>
                            <a href="#section" class="github-star ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150">
                                查看项目
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <img class="absolute top-0 b-auto right-0 pt-16 sm:w-6/12 -mt-48 sm:mt-0 w-10/12 max-h-860-px" :src="patternVue" alt="..." />
        </section>

        <!-- 重低音 -->
        <section id="section" class="mt-48 md:mt-40 relative bg-blueGray-100 test">
            <div class="container mx-auto px-4 pb-32 pt-24">
                <div class="items-center flex flex-wrap">
                    <div class="w-full md:w-5/12 ml-auto px-12 md:px-4">
                        <div class="md:pr-12">
                            <div class="mb-6 flex items-center">
                                <img :src="logoZdy" class="w-24 align-middle rounded-lg max-w-210-px" />
                            </div>
                            <h3 class="text-3xl font-semibold">
                                音乐运营平台
                            </h3>
                            <p class="mt-4 text-lg leading-relaxed text-blueGray-500 t-indent-38">
                                音乐运营平台是集成权限管理、媒体资源、合同信息、财务统计报表的处理系统。对公司的媒体资源、合同资源信息、财务信息的跟踪记录以及日常运营歌曲的排行状况进行管理。提升业务处理效率。
                            </p>
                            <ul class="list-none mt-6">
                                <li class="py-2">
                                    <div class="flex items-center">
                                        <div>
                                            <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3">
                                                <i class="fas fa-fingerprint"></i>
                                            </span>
                                        </div>
                                        <div>
                                            <h4 class="text-blueGray-500">
                                                友好的界面
                                            </h4>
                                        </div>
                                    </div>
                                </li>
                                <li class="py-2">
                                    <div class="flex items-center">
                                        <div>
                                            <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3">
                                                <i class="fab fa-html5"></i>
                                            </span>
                                        </div>
                                        <div>
                                            <h4 class="text-blueGray-500">
                                                媒体资源信息导入导出
                                            </h4>
                                        </div>
                                    </div>
                                </li>
                                <li class="py-2">
                                    <div class="flex items-center">
                                        <div>
                                            <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3">
                                                <i class="far fa-paper-plane"></i>
                                            </span>
                                        </div>
                                        <div>
                                            <h4 class="text-blueGray-500">
                                                动态财务统计报表
                                            </h4>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0">
                        <img alt="..." class="max-w-full img-fluid floating" :src="documentation" />
                    </div>
                </div>
            </div>
        </section>

        <!-- 土农夫 -->
        <section class="relative">
            <div class="container mx-auto pb-40">
                <div class="flex flex-wrap items-center">
                    <div class="w-full md:w-5/12 px-4 mr-auto ml-auto mt-32">
                        <div class="relative flex flex-col min-w-0 w-full mb-6 mt-48 md:mt-0">
                            <img alt="..." :src="componentProfileCard" class="w-full align-middle rounded-lg absolute shadow-lg max-w-210-px left-260-px -top-160-px" />
                            <img alt="..." :src="componentInfoCard" class="w-full align-middle rounded-lg absolute shadow-lg max-w-180-px left-40-px -top-225-px z-2" />
                            <img alt="..." :src="componentInfo2" class="w-full align-middle rounded-lg absolute shadow-2xl max-w-200-px -left-20-px top-25-px" />
                            <img alt="..." :src="componentMenu" class="w-full align-middle rounded absolute shadow-lg max-w-580-px -left-20-px top-210-px" />
                        </div>
                    </div>
                    <div class="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
                        <div class="mb-6 flex items-center">
                            <img :src="qrCode" class="w-24 h-24 align-middle rounded-lg max-w-210-px" />
                            <h3 class="text-3xl ml-4 font-semibold leading-normal">土农夫商城</h3>
                        </div>
                        <p class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600 t-indent-38">
                            一款定位于农场的线上商城小程序，农夫可以在系统后台发布农场图片&介绍、上架农作物，顾客可以在小程序上面查看农场状况，以及购买家禽进行饲养（部分需要购买饲料），饲养完成后邮寄到家；饲养过程中农夫会定时拍照上传到小程序，顾客可以查看购买家禽的实际状况
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <!-- 铁手推广 -->
        <section class="relative bg-blueGray-100">
            <div class="container mx-auto px-4 pb-32 pt-24">
                <div class="items-center flex flex-wrap">
                    <div class="w-full md:w-5/12 ml-auto px-12 md:px-4">
                        <div class="md:pr-12">
                            <div class="mb-6 flex items-center">
                                <img :src="logoTs" class="w-24 align-middle rounded-lg max-w-210-px" />
                            </div>
                            <h3 class="text-3xl font-semibold">
                                音乐征稿系统(铁手推广)
                            </h3>
                            <p class="mt-4 text-lg leading-relaxed text-blueGray-500">
                                一款用于收集&统一管理作品资源的运营推广小程序
                            </p>
                            <ul class="list-none mt-6">
                                <li class="py-2">
                                    <div class="flex items-center">
                                        <div>
                                            <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3">
                                                <i class="fas fa-fingerprint"></i>
                                            </span>
                                        </div>
                                        <div>
                                            <h4 class="text-blueGray-500">
                                                助力高效推广
                                            </h4>
                                        </div>
                                    </div>
                                </li>
                                <li class="py-2">
                                    <div class="flex items-center">
                                        <div>
                                            <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3">
                                                <i class="far fa-paper-plane"></i>
                                            </span>
                                        </div>
                                        <div>
                                            <h4 class="text-blueGray-500">
                                                多维度数据筛选查询
                                            </h4>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="w-full md:w-6/12 flex items-center mr-auto px-4 pt-24 md:pt-0 relative">
                        <div class="p-10 rounded-lg cont-shadow flex-1">
                            <img alt="..." class="max-w-full rounded-md shadow-xl cont-image" :src="tsHome" />
                        </div>
                        <div class="ml-4 p-10 rounded-lg cont-shadow flex-2">
                            <img alt="..." class="max-w-full rounded-md shadow-xl cont-image" :src="tsBack" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="py-20 bg-blueGray-600 overflow-hidden">
            <div class="container mx-auto">
                <div class="flex flex-wrap justify-center">
                    <div class="w-full md:w-4/12 px-4 mr-auto ml-auto mt-32 relative">
                        <i class="fab fa-github text-blueGray-700 text-55 absolute -top-150-px -right-100 left-auto opacity-80"></i>
                    </div>
                </div>
            </div>
        </section>

        <section class="pb-16 bg-blueGray-200 relative pt-32">
            <div class="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20" style="transform: translateZ(0);">
                <svg class="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0">
                    <polygon class="text-blueGray-200 fill-current" points="2560 0 2560 100 0 100"></polygon>
                </svg>
            </div>

            <div class="container mx-auto">
                <div class="flex flex-wrap justify-center bg-white shadow-xl rounded-lg -mt-64 py-16 px-12 relative z-10">
                    <div class="w-full text-center lg:w-8/12">
                        <p class="text-4xl text-center">
                            <span role="img" aria-label="love">
                                😍
                            </span>
                        </p>
                        <h3 class="font-semibold text-3xl">
                            你对我们的项目感兴趣吗?
                        </h3>
                        <p class="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                            如果你有兴趣，或者你有定制化网站、小程序、App的需求，请联系我们！我们将为您梳理功能需求，并提供专业的意见!
                        </p>
                        <div class="sm:block flex flex-col mt-10">
                            <a href="#footer" class="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-emerald-500 active:bg-emerald-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150">
                                立即联系我们
                            </a>
                        </div>
                        <div class="text-center mt-16"></div>
                    </div>
                </div>
            </div>
        </section>
        <footer-component />
    </div>
</template>
<script>
import IndexNavbar from '@/components/Navbars/IndexNavbar.vue'
import FooterComponent from '@/components/Footers/Footer.vue'

import tsHome from '@/assets/img/ts-home.jpg'
import tsBack from '@/assets/img/ts-back.jpg'
import logoTs from '@/assets/img/logo-ts.png'
import logoZdy from '@/assets/img/logo-zdy.png'
import qrCode from '@/assets/img/qr-code.jpg'
import patternVue from '@/assets/img/pattern_vue.png'
import componentProfileCard from '@/assets/img/component-profile-card.png'
import componentInfoCard from '@/assets/img/component-info-card.png'
import componentInfo2 from '@/assets/img/component-info-2.png'
import componentMenu from '@/assets/img/component-menu.png'
import documentation from '@/assets/img/1654825198140.jpg'
import minipro from '@/assets/img/minipro.png'
import miniproback from '@/assets/img/miniproback.jpg'

export default {
    data () {
        return {
            patternVue,
            componentProfileCard,
            componentInfoCard,
            componentInfo2,
            componentMenu,
            documentation,
            minipro,
            miniproback,
            qrCode,
            logoZdy,
            logoTs,
            tsHome,
            tsBack
        }
    },
    components: {
        IndexNavbar,
        FooterComponent
    }
}
</script>
