import { createApp } from 'vue'
import { createWebHistory, createRouter } from 'vue-router'

// styles

import '@fortawesome/fontawesome-free/css/all.min.css'
import '@/assets/styles/tailwind.css'
import '@/assets/styles/index.css'

// mouting point for the whole app

import App from '@/App.vue'
import Index from '@/views/Index.vue'

// routes

const routes = [
    {
        path: '/',
        component: Index
    },
    { path: '/:pathMatch(.*)*', redirect: '/' }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

createApp(App).use(router).mount('#app')
